import React, { Component } from 'react';
import FeatureList from 'pages/analysts/feature_analysis/feature_list.js';
import ResponseList from 'pages/analysts/feature_analysis/response_list.js';
import { Wrapper, Alert, TextInput, FormWithChildren, Button } from 'library';
import { Query, Builder, Utils as QbUtils } from '@react-awesome-query-builder/bootstrap';
import { BootstrapConfig, BootstrapWidgets } from '@react-awesome-query-builder/bootstrap';
import '@react-awesome-query-builder/ui/css/styles.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import QuestionDisplay from 'pages/questions/question_display.js';

import ajaxWrapper from 'base/ajax.js';
import { debounce} from 'functions';
import $ from 'jquery';

const InitialConfig = BootstrapConfig;

const config = {
    ...InitialConfig,
    fields: {
      level: {
        label: 'Feature Level',
        type: 'number',
        fieldSettings: {
          min: 0,
        },
        valueSources: ['value'],
        preferWidgets: ['number'],
      },
      name: {
        label: 'Feature Name',
        type: 'text',
        valueSources: ['value'],
      },
      string_value: {
        label: 'Canonical Value',
        type: 'text',
        valueSources: ['value'],
      },
      confidence: {
        label: 'Confidence',
        type: 'number',
        valueSources: ['value'],
        fieldSettings: {
          min: 0,
          max: 1,
        },
        preferWidgets: ['number'],
      },
    },
    operators: {
        equal: InitialConfig.operators.equal,
        not_equal: InitialConfig.operators.not_equal,
        like: InitialConfig.operators.like,
        less: InitialConfig.operators.less,
        greater: InitialConfig.operators.greater
      },
    settings: {
        ...InitialConfig.settings,
        // canLeaveEmptyGroup: false,
        // shouldCreateEmptyGroup: true
    }
  };

  // You can load query value from your backend storage (for saving see `Query.onChange()`)
  const defaultQueryValue = {"id": QbUtils.uuid(), "type": "group", "children1": []};

export default class StudentThinkingPage extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            feature_list: [], 
            loaded: false, 
            question: {},
            component: {}, 
            scope_filters: {},
            tree: {},
            config: config,
            applying_in_progress: false,
            application_results: {}
        };

        this.update_feature_filters = this.update_feature_filters.bind(this);
        this.saveComponent = this.saveComponent.bind(this);
        this.applyComponent = this.applyComponent.bind(this);
        this.add_group_from_feature_table = this.add_group_from_feature_table.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/approachcomponent/${this.props.component_id}/?related=approach,approach__question,approach__question__student_question_responses__count`,
            {},
            result => {
                let component = result[0]['approachcomponent'];
                let question = component['approach']['question'];
                let filter_tree = component['analysis_features_filter_tree']
                if ($.isEmptyObject(filter_tree)) {
                    filter_tree = defaultQueryValue;
                }
                let checked_tree = QbUtils.checkTree(QbUtils.loadTree(filter_tree), config);
                
                this.setState({
                    component: component,
                    question: question,
                    tree: checked_tree,
                    scope_filters: QbUtils.getTree(checked_tree),
                    loaded: true
                });
            }
        );

    }

    update_feature_filters(data) {
        this.setState({ scope_filters: data.scope_filters });
    }

    renderBuilder = (props) => (
        <div className="query-builder-container" style={{paddingLeft: '10px', paddingRight: '10px'}}>
          <div className="query-builder">
            <Builder {...props} />
          </div>
        </div>
    )

    onChangeQuery = (immutableTree, config) => {
        // Tip: for better performance you can apply `throttle` - see `examples/demo`
        this.setState({tree: immutableTree, config: config},this.updateServerSide());    
    }

    add_group_from_feature_table = (feature_name, canonical_value, confidence) => {
        let currentTree = QbUtils.getTree(this.state.tree);
        let newGroup = {
            id: QbUtils.uuid(),
            type:"group",
            properties: { conjunction: "AND" },
            children1:[
                { id: QbUtils.uuid(), type:"rule", properties:{ field:"name", value:[feature_name], fieldSrc:"field", operator:"equal", valueSrc:["value"], valueType:["text"]}},
                { id: QbUtils.uuid(), type:"rule", properties:{ field:"string_value", value:[canonical_value], fieldSrc:"field", operator:"equal", valueSrc:["value"], valueType:["text"]}},
                { id: QbUtils.uuid(), type:"rule", properties:{ field:"confidence", value:[confidence], fieldSrc:"field", operator:"equal", valueSrc:["value"], valueType:["number"]}}
            ]
        }
        currentTree.children1.push(newGroup);
        this.onChangeQuery(QbUtils.loadTree(currentTree),this.state.config);
    }

    saveComponent() {
        ajaxWrapper(
            'POST',
            `/api/home/approachcomponent/${this.props.component_id}/`,
            {
                analysis_features_filter_tree: this.state.scope_filters
            },
            result => {
                window.location.reload();
            }
        )
    }

    applyComponent() {
        this.setState({applying_in_progress: true})
        ajaxWrapper(
            'POST',
            `/api/home/approachcomponent/${this.props.component_id}/`,
            {
                analysis_features_filter_tree: this.state.scope_filters
            },
            result => {
                ajaxWrapper(
                    'POST',
                    `/analysis/attach_component_for_matching_filters/${this.props.question_id}/${this.props.component_id}/`,
                    {},
                    result => {
                        this.setState({
                            applying_in_progress: false,
                            application_results: result
                        })
                    }
                )
            }
        )
    } 
    
    updateServerSide = debounce(() => {
        let checkedTree = QbUtils.checkTree(this.state.tree, this.state.config);
        let jsonTree = QbUtils.getTree(checkedTree);
        // `jsonTree` can be saved to backend, and later loaded to `queryValue`
        this.setState({scope_filters: jsonTree});
    }, 500)

    render() {
        let apply_message = null;
        if (this.state.applying_in_progress) {
            apply_message = (
                <Alert
                    type="warning"
                    text="Analysis is in progress.  DO NOT leave this page."
                />
            )
        }
        else if (!$.isEmptyObject(this.state.application_results)) {
            let results = this.state.application_results
            apply_message = (
                <Alert
                    type="success"
                    text={`Component applied with results: New Yes Components: ${results.new_yes_components}, New No Components: ${results.new_no_components}, Components flipped to yes: ${results.num_flipped_true} \n Components flipped to no: ${results.num_flipped_false}`}
                />
            )
        }
        var content = (
            <div style={{paddingLeft: '10px'}}>
                <Button
                            type="link"
                            text={[
                                <i className="fas fa-arrow-left" />,
                                <span style={{ marginLeft: '5px' }}>
                                    Back to Question
                                </span>,
                            ]}
                            href={`/scoring_configuration/question_component_overview/${this.props.question_id}/`}
                />
                <Button
                    type="link"
                    text="MCL Scoring"
                    href={
                        '/response_scoring/' +
                        this.props.question_id +
                        '/'
                    }
                />
                <Alert type="info">
                        <QuestionDisplay
                            question={this.state.question}
                            display="full_text"
                            image
                        />
                </Alert>
                <Alert type="info">
                    <div>
                        <span>
                            <b>{this.state.component.name}: </b>
                        </span>
                        <span>{this.state.component.text}</span>
                    </div>
                </Alert>
                <h1>Student Thinking Exploration: {this.state.question.student_question_responses} responses</h1>
                <Button
                            
                            type="primary"
                            onClick={this.saveComponent}
                            text="Save Current Ruleset As Component"
                        />
                <Button
                            
                            type="primary"
                            onClick={this.applyComponent}
                            text="Apply Current Component Ruleset for Analysis"
                        />
                {apply_message}
            
                <div>
                    <h3>Current Component Ruleset:</h3>
                    <Query
                        {...config} 
                        value={this.state.tree}
                        onChange={this.onChangeQuery}
                        renderBuilder={this.renderBuilder}
                    />
                </div>
                <div className="row">
                    <div className="col-5" test-data-id="feature-list" style={{'border-right': "1px solid black"}}><FeatureList question_id={this.state.question.id} scope_filters={this.state.scope_filters} add_group_from_feature_table={this.add_group_from_feature_table}/></div>

                    <div className="col-7" test-data-id="response-list" style={{'border-left': "1px solid black"}}><ResponseList question_id={this.state.question.id} scope_filters={this.state.scope_filters}/></div>
                </div>
            </div>
        )

        return <Wrapper content={content} loaded={this.state.loaded} style={{ marginTop: '0px' }} />;
    }

}