import { BASE_DOMAIN } from 'constants';
import React, { Component } from 'react';

export default class ContactUs extends Component {
    render() {
        return (
            <div className="site-wrapper overflow-hidden position-relative">
                <header className="site-header site-header--menu-right landing-14-menu site-header--absolute site-header--sticky">
                    <div className="container">
                        <nav className="navbar site-navbar">
                            <div className="brand-logo">
                                <a href={`${BASE_DOMAIN}`}>
                                    <img
                                        src="https://mathanex-media.learning.amplify.com/MathANEXLogo.png"
                                        alt=""
                                        style={{ height: '40px' }}
                                    />{' '}
                                    Math ANEX
                                </a>
                            </div>
                            <div className="menu-block-wrapper">
                                <div className="menu-overlay" />
                                <nav
                                    className="menu-block"
                                    id="append-menu-header"
                                >
                                    <div className="mobile-menu-head">
                                        <div className="go-back">
                                            <i className="fa fa-angle-left" />
                                        </div>
                                        <div className="current-menu-title" />
                                        <div className="mobile-menu-close">
                                            &times;
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div className="ms-auto d-none d-xs-inline-flex">
                                <a
                                    className="btn btn-success focus-reset"
                                    href="/"
                                >
                                    Home
                                </a>
                            </div>
                            <div className="ms-auto d-none d-xs-inline-flex">
                                <a
                                    target="_blank"
                                    className="btn btn-primary focus-reset"
                                    href="/login/"
                                >
                                    Log In
                                </a>
                            </div>

                            <div className="mobile-menu-trigger">
                                <span />
                            </div>
                        </nav>
                    </div>
                </header>

                <div className="cta-area-l-14">
                    <div className="container position-relative">
                        <div className="" />
                        <div className="row cta-area-l-14-content justify-content-center text-lg-start text-left">
                            <div className="col-lg-12">
                                <div className="cta-content">
                                    <h1>Math ANEX General Terms of Use</h1>
                                    <p>Effective Date: June 30, 2022</p>
                                    <p>
                                        Math ANEX (“Math ANEX,” “we,” “our” or
                                        “us”), is a service (the “Service”)
                                        developed by Math ANEX, Inc., a Delaware
                                        corporation (the “Company”) and its
                                        affiliates, for understanding student
                                        thinking and taking informed action on that knowledge. The application supplies users
                                        with a platform for solving problems,
                                        and for supporting the interaction
                                        between students (“Student User”),
                                        teachers, and administrators (“Educator
                                        User”) in an education setting. By using
                                        the Service, you are subject to the
                                        following Terms of Use (“Terms”). Every
                                        time users (“users,” “you” or “your”)
                                        visit or use features of the Service,
                                        you agree to be bound by these Terms.
                                        These Terms outline your rights,
                                        obligations and restrictions regarding
                                        your use of the Service, please read
                                        them carefully. If you do not agree to
                                        be bound by the Terms and all applicable
                                        laws, you should discontinue use of the
                                        Service immediately. Math ANEX may
                                        modify the Terms from time to time and
                                        each modification will be effective when
                                        it is posted on the Service. You agree
                                        to be bound to any changes to the Terms
                                        through your continued use of the
                                        Service.
                                    </p>
                                    <p>
                                        In addition to these Terms, we have
                                        adopted the Math ANEX{' '}
                                        <a href="/privacy_policy">
                                            Privacy Policy
                                        </a>{' '}
                                        (“
                                        <a href="/privacy_policy">
                                            Privacy Policy
                                        </a>
                                        ”). Please read our{' '}
                                        <a href="/privacy_policy">
                                            Privacy Policy
                                        </a>{' '}
                                        carefully for information relating to
                                        our collection, use, and disclosure of
                                        your personal information.
                                    </p>

                                    <h3>USER ELIGIBILITY</h3>
                                    <p>
                                        Math ANEX is a platform for all levels
                                        of students and their educators.
                                        Accordingly, we are subject to the
                                        Children’s Online Privacy and Protection
                                        Act (“COPPA”) and Family Educational Rights and Privacy Act ("FERPA"). We only collect personal
                                        information through the Services for the
                                        use and benefit of the educational
                                        institution/school, and for no other
                                        commercial purpose. In such a case,
                                        according to the FTC guidance #N 1, the
                                        educational institution can act as the
                                        parent’s agent and can consent under
                                        COPPA to the collection of kids’
                                        information on the parent’s behalf. If
                                        you are a teacher, you represent and
                                        warrant that you have permission and
                                        authorization from your school and/or
                                        district to use the Services, and for
                                        purposes of COPPA compliance, you
                                        represent and warrant that you are
                                        entering into these Terms on behalf of
                                        your school and/or district, and that
                                        you are consenting under COPPA to the
                                        collections of kids’ information on the
                                        parent’s behalf.
                                    </p>

                                    <p>
                                        If you are a student under 13, please do
                                        not send any personal information about
                                        yourself to us if your school, district,
                                        and/or teacher has not obtained this
                                        prior consent from your educational
                                        institution, teacher, parent or
                                        guardian, and please do not send any
                                        personal information other than what we
                                        request from you in connection with the
                                        Services.
                                    </p>

                                    <p>
                                        If we learn we have collected personal
                                        information from a student under 13
                                        without the proper consent being
                                        obtained by his or her school, district,
                                        and/or teacher, or if we learn a student
                                        under 13 has provided us personal
                                        information beyond what we request from
                                        him or her, we will delete that
                                        information as quickly as possible. If
                                        you believe that a student under 13 may
                                        have provided us personal information in
                                        violation of this paragraph, please
                                        contact us at support@mathanex.com.
                                    </p>

                                    <p>
                                        You represent and warrant that you are
                                        of legal age to form a binding contract
                                        (or if not, you’ve received your
                                        parent’s or guardian’s permission to use
                                        the Services and gotten your parent or
                                        guardian to agree to these Terms on your
                                        behalf, as we described earlier). If
                                        you’re agreeing to these Terms on behalf
                                        of an organization or entity (for
                                        example, if you’re an administrator or
                                        teacher agreeing to these Terms on
                                        behalf of your district), you represent
                                        and warrant that you are authorized to
                                        agree to these Terms on that
                                        organization or entity’s behalf and bind
                                        them to these Terms.
                                    </p>

                                    <h3>
                                        CREATING AN ACCOUNT AND CONTRIBUTING
                                        CONTENT
                                    </h3>

                                    <p>
                                        Educator Users and Student Users are
                                        currently required to have an account
                                        (your “Account”) to access certain
                                        features of the Service. To create an
                                        Educator User Account, the user must
                                        sign up for the Services on the Site. To
                                        create a Student User Account, the
                                        Educator User must add students through
                                        syncing data via Clever, Classlink or a CSV sent through a secure channel.
                                    </p>

                                    <p>
                                        From your 
                                        Student User Account, you can create and
                                        edit your work product on an assessment. Such work
                                        product will typically consist of math
                                        problems and solutions, and may include
                                        text or whiteboard images, graphics,
                                        videos, photographs, audio clips,
                                        comments, or other material and
                                        information, and associated trademarks
                                        and copyrightable works (such data,
                                        information and content, “Content”). You
                                        may also use your Account to view
                                        Content created by Math ANEX. Educator
                                        Users may use their Account to log into
                                        a “Teacher Dashboard” where they can
                                        view student work.
                                    </p>

                                    <p>
                                        From your Student User Account, you can
                                        access the content posted and assigned
                                        by your School District and complete said
                                        assignments by contributing Content.
                                    </p>

                                    <p>
                                        Math ANEX does not warrant that product
                                        or service descriptions, or other
                                        Content created by Math ANEX is
                                        accurate, complete, reliable, current,
                                        or error-free. If you are dissatisfied
                                        with the Service, please provide
                                        feedback through the provided feedback
                                        mechanisms. Your only other remedy (and
                                        Math ANEX’s sole liability) with respect
                                        to any dissatisfaction with the Service,
                                        these Terms or the{' '}
                                        <a href="/privacy_policy">
                                            Privacy Policy
                                        </a>{' '}
                                        is to cease using Math ANEX and
                                        terminate your Account. Please refer to
                                        our{' '}
                                        <a href="/privacy_policy">
                                            Privacy Policy
                                        </a>{' '}
                                        for information about how we protect
                                        your Account and Content.
                                    </p>

                                    <h3>Your Account Information</h3>

                                    <p>
                                        As noted above, student users are created via a data transfer between Clever, Classlink or other secure means.
                                        Additionally, all educator and administrator accounts are created in a similar manner.
                                    </p>

                                    <p>
                                        You are responsible for ensuring that
                                        any personal information you provide is
                                        accurate and up to date. Math ANEX
                                        reserves the right to verify the
                                        accuracy of the information you provide
                                        at any time. You agree to
                                        notify Math ANEX immediately if you
                                        believe your username, password or other
                                        identifying information has been lost,
                                        stolen or otherwise compromised. You
                                        will be held responsible for any
                                        activity that occurs under your Account.
                                        You also acknowledge and agree that you
                                        are solely responsible for all damages
                                        or claims that may arise from any access
                                        to or use of this Service by any person
                                        to whom you have provided your user
                                        identification, password or other
                                        identifying information, or by any
                                        person who has obtained such information
                                        from you, including, but not limited to,
                                        any access to or use of this Service
                                        that may occur after you have notified
                                        us that your user identification,
                                        password or other identifying
                                        information has been lost, stolen or
                                        otherwise compromised.
                                    </p>

                                    <p>
                                        You will not impersonate, abuse, harass,
                                        threaten, stalk or intimidate other
                                        people from your Account.
                                    </p>

                                    <h3>Contributing Content</h3>

                                    <p>
                                        By contributing Content to Math ANEX,
                                        you acknowledge and agree to the
                                        following terms and our{' '}
                                        <a href="/privacy_policy">
                                            Privacy Policy
                                        </a>
                                        . You should only contribute Content if
                                        you are comfortable with these terms:
                                    </p>

                                    <p>
                                        Your Content will be visible only to
                                        those users (whether teachers,
                                        administrators, students, or classroom
                                        groups) within your classroom or
                                        otherwise approved by you or the Administrators
                                        that administers your account. Content
                                        that is offensive, indecent,
                                        objectionable, or otherwise
                                        inappropriate may be flagged and removed
                                        without notice. All content posted on
                                        the Service must comply with all
                                        applicable laws (including, without
                                        limitation, U.S. copyright law). You
                                        represent and warrant that (i) you own
                                        or otherwise possess all rights to use
                                        your Content; (ii) you have the
                                        permission to use the name and likeness
                                        of each identifiable individual person
                                        and to use such individual's identifying
                                        or personal information; (iii) you are
                                        authorized to grant all of the rights
                                        described in these Terms; and (iv) the
                                        use of your Content as contemplated by
                                        these Terms will not infringe or violate
                                        any intellectual property, privacy,
                                        publicity, contract or other rights of
                                        any person or entity. If you do not have
                                        the right to submit Content for such
                                        use, it may subject you to liability.
                                        You will be held solely responsible and
                                        liable for your Content and conduct on
                                        the Math ANEX Service. Math ANEX will
                                        not be responsible or liable for any use
                                        of your Content by Math ANEX in
                                        accordance with these Terms. You
                                        represent and warrant that you have all
                                        the rights, power, and authority
                                        necessary to grant the rights granted
                                        herein to any Content that you submit.
                                        Your Content may be modified or adapted
                                        (for example, photos may be cropped) in
                                        order to meet design and technical
                                        requirements of the Service or for any
                                        other reason. You are responsible for
                                        retaining original copies of Content you
                                        upload from a local source. Your Content
                                        always belongs to you. You retain
                                        copyright and any other rights you
                                        already hold in your Content, but by
                                        uploading, posting, contributing, or
                                        otherwise providing your Content to Math
                                        ANEX, you grant Math ANEX a limited,
                                        nonexclusive license solely for the
                                        purpose of performing Math ANEX’s
                                        obligations as outlined in these Terms.
                                        For clarity, the foregoing license grant
                                        does not affect your other ownership or
                                        license rights in your Content,
                                        including the right to grant additional
                                        licenses to the material in your
                                        Content, unless otherwise agreed in
                                        writing.
                                    </p>

                                    <p>
                                        Additionally, by distributing or
                                        disseminating your Content on the
                                        Service you hereby grant to each user to
                                        whom you have authorized access to your
                                        Content a non-exclusive license to use
                                        your Content under the terms indicated
                                        by you when you uploaded or created such
                                        Content, which shall at least include a
                                        limited, non-exclusive, personal license
                                        to view, download, and alter such
                                        Content in the manner contemplated by
                                        these Terms and the Service.
                                    </p>

                                    <p>
                                        Be sure to review our{' '}
                                        <a href="/privacy_policy">
                                            Privacy Policy
                                        </a>{' '}
                                        to better understand how your public and
                                        private information is used and
                                        protected.
                                    </p>

                                    <h3>
                                        Removal of Your Account and Your Content
                                    </h3>

                                    <p>
                                        Your school district administrators may disable your Account at any time
                                        via Clever, Classlink or via a request to support@mathanex.com. Disabling
                                        your Account does not automatically
                                        delete your personal data. 
                                    </p>

                                    <p>
                                        We reserve the right to monitor and
                                        review your Account, your Content, and
                                        your activity for compliance with these
                                        Terms, and may remove or disable your
                                        Account or Content for any reason,
                                        including, but not limited to, violation
                                        of Terms, alleged infringement, or
                                        verbal, physical, written or other abuse
                                        of a Math ANEX user, employee, member or
                                        officer. We will do our best to
                                        communicate with you via your Account
                                        email address prior to the removal of
                                        your Account or your Content, but are
                                        not obligated to do so and cannot be
                                        responsible for failure to reach you via
                                        email.
                                    </p>

                                    <h3>
                                        FEES, GUIDELINES, RIGHTS AND REMEDIES
                                    </h3>

                                    <p>Subscription Fees</p>

                                    <p>
                                        Creating an Account with Math ANEX is
                                        free. However, to use the service over
                                        time we reserve the right to charge a
                                        subscription fee to teachers or
                                        administrators to use the Service.
                                    </p>

                                    <p>
                                        You are responsible for any fees charged
                                        by your internet service provider or
                                        mobile carrier for using the Service,
                                        including, but not limited to data
                                        transfer fees.
                                    </p>

                                    <h3>Permitted Use of the Service</h3>

                                    <p>
                                        The Service is available to its users
                                        for educational, non-commercial use
                                        only. Users may not modify the Service,
                                        or any portion of it, except with
                                        express written consent of Math ANEX.
                                        You agree that you will not use or
                                        attempt to use this Service for any
                                        purpose other than as described herein.
                                        Math ANEX reserves the right at all
                                        times and for any reason or for no
                                        reason at all, in its sole discretion
                                        and without notice to you, to deny your
                                        access to and use of this Service.
                                    </p>

                                    <h3>Intellectual Property Rights</h3>

                                    <p>
                                        We and our licensors own and retain all
                                        proprietary rights in the Service. The
                                        Service or any portion of the Service
                                        may not be reproduced, duplicated,
                                        copied, sold, resold, visited, or
                                        otherwise exploited for any commercial
                                        purpose without express written consent
                                        of Math ANEX, unless it is in the public
                                        domain. You may not frame or utilize
                                        framing techniques to enclose any
                                        trademark, logo, or other proprietary
                                        information (including images, text,
                                        page layout, or form) of Math ANEX
                                        without express written consent. You may
                                        not use any meta tags or any other
                                        "hidden text" utilizing Math ANEX's name
                                        or trademarks without the express
                                        written consent of Math ANEX. You may
                                        not (directly or indirectly) decipher,
                                        decompile, disassemble, reverse engineer
                                        or otherwise attempt to derive source
                                        code or underlying ideas or algorithms
                                        of any part of the Service, or modify,
                                        translate or otherwise create derivative
                                        works of any part of the Service. Any
                                        modification of the Service, or any
                                        portion thereof, or use of the Service
                                        for any other purpose constitutes an
                                        infringement of trademark or other
                                        proprietary rights of Math ANEX or third
                                        parties, and any unauthorized use
                                        terminates the permission to use the
                                        Service granted by Math ANEX.
                                    </p>

                                    <p>
                                        The marks appearing on this Service,
                                        including but not limited to Math ANEX
                                        and any respective logos, emblems,
                                        slogans and designs, are trademarks of
                                        Math ANEX. All other marks used on this
                                        Service are the property of their
                                        respective owners.
                                    </p>

                                    <h3>
                                        DMCA Notice and Take Down Procedures
                                    </h3>

                                    <p>i. Notice of Claims</p>

                                    <p>
                                        If you believe any post or information
                                        on the Service infringes your copyright
                                        or trademark rights, you may request
                                        such content be removed by following the
                                        notice and take down procedures of the
                                        Digital Millennium Copyright Act (DMCA).
                                        To follow those procedures, contact Math
                                        ANEX’s copyright agent (identified
                                        below) and provide the following
                                        information:
                                    </p>

                                    <p>
                                        A clear statement identifying the works,
                                        or other materials believed to be
                                        infringed. A statement from the
                                        copyright holder or authorized
                                        representative that the content is
                                        believed to be infringing. Sufficient
                                        information about the location of the
                                        allegedly infringing content so that
                                        Math ANEX can find and verify its
                                        existence. Your name, telephone number
                                        and email address. A statement from you
                                        under penalty of perjury that the
                                        information supplied is accurate, and
                                        that you are authorized to act on the
                                        copyright owner's behalf. A signature or
                                        the electronic equivalent from the
                                        copyright holder or authorized
                                        representative. You acknowledge that if
                                        you fail to comply with all of the
                                        requirements of this section your DMCA
                                        notice may be invalid.
                                    </p>

                                    <p>ii. Counter-Notice</p>

                                    <p>
                                        If you believe that your removed content
                                        (or content to which access was
                                        disabled) is not infringing, or that you
                                        have the authorization from the
                                        copyright owner, the copyright owner's
                                        agent, or pursuant to the law, to post
                                        and use the material in your content,
                                        you may send a counter-notice containing
                                        the following information to the
                                        Copyright Agent: Your physical or
                                        electronic signature; Identification of
                                        the content that has been removed or to
                                        which access has been disabled and the
                                        location at which the content appeared
                                        before it was removed or disabled; A
                                        statement that you have a good faith
                                        belief that the content was removed or
                                        disabled as a result of mistake or a
                                        misidentification of the content; and
                                        Your name, address, telephone number,
                                        and email address, a statement that you
                                        consent to the jurisdiction of the state
                                        or federal courts of The Commonwealth of
                                        Massachusetts, and a statement that you
                                        will accept service of process from the
                                        person who provided notification of the
                                        alleged infringement. If a
                                        counter-notice is received by the
                                        Copyright Agent, we may send a copy of
                                        the counter-notice to the original
                                        complaining party informing that person
                                        that we may replace the removed content
                                        or cease disabling it in 10 business
                                        days. Unless the copyright owner files
                                        an action seeking a court order against
                                        the content provider, member or user,
                                        the removed content may be replaced, or
                                        access to it restored in 10 business
                                        days or more after receipt of the
                                        counter-notice, at our sole discretion.
                                        Math ANEX’ agent for notice of copyright
                                        issues on the Service can be reached as
                                        follows:
                                    </p>

                                    <p>Math ANEX, Inc.</p>

                                    <p>441 B Avenue, Coronado, CA 92118</p>

                                    <p>Attn: Copyright Agent</p>

                                    <p>
                                        If you are not sure whether material
                                        available online infringed your
                                        copyright, we suggest that you first
                                        contact an attorney.
                                    </p>

                                    <h3>GENERAL INFORMATION</h3>

                                    <p>Linking to Third Party Websites</p>

                                    <p>
                                        For your convenience, the Service may
                                        provide links to third-party websites,
                                        including our Payment Processor. Unless
                                        expressly stated otherwise, Math ANEX
                                        does not endorse, approve, sponsor or
                                        control, and we are not in any way
                                        responsible for, any of the content,
                                        services, calculations, information,
                                        products or materials available at or
                                        through any websites to which this
                                        Service may provide a link. By using the
                                        Service you acknowledge and agree that
                                        Math ANEX will not be responsible or
                                        liable to you or any other person for
                                        any damages or claims that might result
                                        from your use of such content, services,
                                        calculation, information, products or
                                        materials. You should carefully review
                                        each website’s privacy statements and
                                        conditions of use to understand your
                                        rights and responsibilities.
                                    </p>

                                    <h3>Indemnification</h3>

                                    <p>
                                        You agree to indemnify and hold Math
                                        ANEX, its parent, subsidiaries,
                                        affiliates, directors, officers, agents,
                                        and other partners and employees,
                                        harmless from and against any and all
                                        claims, damages, obligations, losses,
                                        liabilities, costs or debt, including
                                        but not limited to reasonable attorneys’
                                        fees, made by any third party due to or
                                        arising out of your Content, Account,
                                        use of the Service, or violation of the
                                        Terms. This defense and indemnification
                                        obligation will survive these Terms and
                                        your use of the Service.
                                    </p>

                                    <h3>
                                        Disclaimer & Limitation of Liability
                                    </h3>

                                    <p>
                                        THE SERVICE IS PROVIDED BY Math ANEX ON
                                        AN "AS IS" AND "AS AVAILABLE" BASIS.
                                        Math ANEX MAKES NO REPRESENTATIONS OR
                                        WARRANTIES OF ANY KIND, EXPRESS OR
                                        IMPLIED, IMPLIED WARRANTIES OF
                                        MERCHANTABILITY, OR FITNESS FOR A
                                        PARTICULAR PURPOSE, AS TO (1) THE
                                        OPERATION OF THE SERVICE, (2) THE
                                        QUALITY, ACCURACY, COMPLETENESS OR
                                        VALIDITY OF USER OR OTHER CONTENT ON THE
                                        SERVICE, OR THE INFORMATION, USER OR
                                        OTHER CONTENT, OR PRODUCTS INCLUDED ON
                                        THE SERVICE, OR (3) WHETHER THE
                                        FUNCTIONS CONTAINED ON THE SERVICE WILL
                                        BE UNINTERRUPTED OR ERROR FREE, OR THAT
                                        DEFECTS WILL BE CORRECTED, INCLUDING BUT
                                        NOT LIMITED TO, WARRANTIES OF TITLE. YOU
                                        EXPRESSLY AGREE THAT YOUR USE OF THE
                                        SERVICE IS AT YOUR SOLE RISK.
                                    </p>

                                    <p>
                                        THIS SERVICE MAY ALSO CONTAIN FACTS,
                                        VIEWS, OPINIONS, STATEMENTS, AND
                                        RECOMMENDATIONS OF THIRD PARTY
                                        INDIVIDUALS AND ORGANIZATIONS. Math ANEX
                                        DOES NOT REPRESENT OR ENDORSE THE
                                        ACCURACY, TIMELINESS, OR RELIABILITY OF
                                        ANY OPINION, STATEMENT, OR OTHER
                                        INFORMATION DISPLAYED, UPLOADED, OR
                                        DISTRIBUTED THROUGH THE SERVICE, OR ANY
                                        USER CONTENT OR CONDUCT. YOU ACKNOWLEDGE
                                        THAT ANY RELIANCE ON ANY SUCH OPINION,
                                        STATEMENT, INFORMATION, OR USER CONTENT
                                        OR CONDUCT WILL BE AT YOUR SOLE RISK.
                                    </p>

                                    <p>
                                        YOU AGREE THAT UNDER NO CIRCUMSTANCES
                                        WILL Math ANEX BE LIABLE TO YOU OR ANY
                                        OTHER PERSON OR ENTITY FOR ANY SPECIAL,
                                        INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR
                                        OTHER INDIRECT DAMAGES THAT RESULT FROM
                                        THE USE OF, OR THE INABILITY TO USE, THE
                                        SERVICE OR THE INFORMATION CONTAINED ON
                                        THE SERVICE, EVEN IF PREVIOUSLY ADVISED
                                        OF THE POSSIBILITY OF SUCH DAMAGES. IN
                                        NO EVENT SHALL THE TOTAL LIABILITY OF
                                        Math ANEX TO YOU FOR ALL DAMAGES,
                                        LOSSES, AND CAUSES OF ACTION RESULTING
                                        FROM YOUR USE OF THE SERVICE, WHETHER IN
                                        CONTRACT, TORT (INCLUDING, BUT NOT
                                        LIMITED TO, NEGLIGENCE) OR OTHERWISE,
                                        EXCEED ONE DOLLAR (US $1.00).
                                    </p>

                                    <p>
                                        CERTAIN STATE LAWS DO NOT ALLOW
                                        LIMITATIONS ON IMPLIED WARRANTIES OR THE
                                        EXCLUSION OR LIMITATION OF CERTAIN
                                        DAMAGES. IF THESE LAWS APPLY TO YOU,
                                        SOME OR ALL OF THE ABOVE DISCLAIMERS,
                                        EXCLUSIONS, OR LIMITATIONS MAY NOT
                                        APPLY, AND YOU MIGHT HAVE ADDITIONAL
                                        RIGHTS.
                                    </p>

                                    <p>
                                        ELECTRONIC COMMUNICATIONS PRIVACY ACT
                                        NOTICE (18 USC 2701-2711): Math ANEX
                                        MAKES NO GUARANTY OF CONFIDENTIALITY OR
                                        PRIVACY OF ANY COMMUNICATION OR
                                        INFORMATION TRANSMITTED ON THE SERVICE
                                        OR ANY WEBSITE LINKED TO THE SERVICE.
                                    </p>

                                    <p>
                                        While we strive to protect your
                                        information in accordance with our{' '}
                                        <a href="/privacy_policy">
                                            Privacy Policy
                                        </a>
                                        , Math ANEX cannot be liable for the
                                        privacy of email addresses, registration
                                        and identification information, disk
                                        space, communications, confidential or
                                        trade-secret information, or any other
                                        Content stored on Math ANEX’ equipment,
                                        transmitted over networks accessed by
                                        the Service, or otherwise connected with
                                        your use of the Service.
                                    </p>

                                    <h3>Termination</h3>

                                    <p>
                                        Math ANEX may, in its sole discretion,
                                        terminate or suspend your access to all
                                        or part of the Service, for any reason,
                                        including without limitation, your
                                        breach of these Terms. In the event
                                        these Terms are terminated, the
                                        restrictions regarding content appearing
                                        on the Service, and the representations
                                        and warranties, indemnities, and
                                        limitations of liabilities set forth in
                                        these Terms will survive termination. In
                                        the event that you are unsatisfied with
                                        the services provided by Math ANEX, your
                                        sole remedy is to terminate your use of
                                        the Service.
                                    </p>

                                    <h3>Jurisdiction & Severability</h3>

                                    <p>
                                        Math ANEX operates the Service from its
                                        offices within the United States. Math
                                        ANEX makes no representations that
                                        content and materials on the Service are
                                        legal or appropriate for use from
                                        outside the United States. If you choose
                                        to access the Service from other
                                        locations, you do so at your own risk
                                        and are responsible for compliance with
                                        any and all local laws. You may not use
                                        the Service in violation of U.S. export
                                        laws and regulations.
                                    </p>

                                    <p>
                                        These Terms are personal to you, and are
                                        not assignable, transferable, or
                                        sublicensable by you except with Math
                                        ANEX’s prior written consent. Math ANEX
                                        may assign, transfer, or delegate any of
                                        its rights and obligations hereunder
                                        without consent.
                                    </p>

                                    <p>
                                        These Terms will be governed by and
                                        construed in accordance with the laws of
                                        the state of Delaware. Any action
                                        brought to enforce these Terms or
                                        matters related to the Service will be
                                        brought in either the State or Federal
                                        Courts of the state of Delaware. Any
                                        claim or cause of action you have with
                                        respect to use of the Service must be
                                        commenced within one (1) year after the
                                        claim arises. In any action or
                                        proceeding to enforce rights under the
                                        Terms, the prevailing party will be
                                        entitled to recover costs and attorneys'
                                        fees.
                                    </p>

                                    <p>
                                        If any provision of these Terms is
                                        deemed void, unlawful, or otherwise
                                        unenforceable for any reason, that
                                        provision will be severed from these
                                        Terms and the remaining provisions of
                                        these Terms will remain in force. These
                                        Terms constitute the entire agreement
                                        between you and Math ANEX concerning
                                        your use of the Service.
                                    </p>

                                    <h3>HOW TO CONTACT US</h3>

                                    <p>
                                        Should you have any questions or
                                        complaints regarding violations of these
                                        Terms, please contact us at:
                                    </p>

                                    <p>Email address: support@mathanex.com</p>

                                    <p>
                                        Postal address: 441 B Avenue, Coronado,
                                        CA 92118
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
