import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import {
    TextInput,
    Select,
    NumberInput,
    Alert,
    FormWithChildren,
    Wrapper,
} from 'library';

class MCLMapping extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.submit = this.submit.bind(this);
        this.submit_callback = this.submit_callback.bind(this);
    }

    submit(state, callback) {
        console.log('QuestionMCLMapping', state);

        const mapping = {
            cutoffs: {
                m: state.m_cutoff,
                M: state.M_cutoff,
                l: state.l_cutoff,
                L: state.L_cutoff,
            },
            m_components: state.m_components,
            l_components: state.l_components,
            order: state.order,
        };

        state.mapping = mapping;

        let submit_url = '/api/home/questionmclmapping/';
        if ('id' in state) {
            submit_url += `${state.id}/`;
        }

        this.setState({
            form_callback: callback,
        });

        ajaxWrapper('POST', submit_url, state, this.submit_callback);
    }

    submit_callback(result) {
        const { form_callback } = this.state;
        form_callback({});
    }

    render() {
        let content = null;
        let { mcl_mapping } = this.props;

        const urls = {};
        let title = 'Add New';

        if (mcl_mapping) {
            const { mapping } = mcl_mapping;
            mcl_mapping.m_components = mapping.m_components || [];
            mcl_mapping.l_components = mapping.l_components || [];

            const cutoffs = mapping.cutoffs || {};
            mcl_mapping.m_cutoff = cutoffs.m || '';
            mcl_mapping.M_cutoff = cutoffs.M || '';
            mcl_mapping.l_cutoff = cutoffs.l || '';
            mcl_mapping.L_cutoff = cutoffs.L || '';

            mcl_mapping.order = mapping.order || 0;

            urls.deleteUrl =
                `/api/home/questionmclmapping/${mcl_mapping.id
                }/delete/`;

            title = mcl_mapping.name;
        } else {
            mcl_mapping = {
                question: this.props.question_id,
            };
        }

        const form = (
            <div className="box-item">
                <FormWithChildren
                    className="row"
                    defaults={mcl_mapping}
                    submit={this.submit}
                    redirect={this.props.refresh_data}
                    {...urls}
                >
                    <h5 className="col-12">{title}</h5>
                    <TextInput className="col-12" name="name" label="Name" />
                    <NumberInput className="col-6" name="order" label="Order" />

                    <Select
                        className="col-12"
                        name="m_components"
                        label="Math Components"
                        options={this.props.component_options}
                        multiple
                    />
                    <Select
                        className="col-12"
                        name="l_components"
                        label="Logic Components"
                        options={this.props.component_options}
                        multiple
                    />

                    <div className="col-12">
                        <Alert type="info">
                            Number of components required (leave blank for impossible)
                        </Alert>
                    </div>
                    <NumberInput
                        className="col-6"
                        name="m_cutoff"
                        label="m Components"
                    />
                    <NumberInput
                        className="col-6"
                        name="M_cutoff"
                        label="M Components"
                    />
                    <NumberInput
                        className="col-6"
                        name="l_cutoff"
                        label="l Components"
                    />
                    <NumberInput
                        className="col-6"
                        name="L_cutoff"
                        label="L Components"
                    />

                    <TextInput name="question" style={{ display: 'none' }} />
                </FormWithChildren>
            </div>
        );

        content = form;

        return content;
    }
}

class MCLMappings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            question: null,
            mcl_mappings: [],
            component_options: [],
            loaded: false,
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.mcl_mapping_callback = this.mcl_mapping_callback.bind(this);
        this.load_approaches = this.load_approaches.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/approach/?related=components&question=${this.props.question_id}`,
            {},
            this.load_approaches
        );

        this.refresh_data();
    }

    refresh_data() {
        const url =
            `/api/home/questionmclmapping/?question=${this.props.question_id
            }&order_by=order`;
        ajaxWrapper('GET', url, {}, this.mcl_mapping_callback);
    }

    load_approaches(result) {
        const component_options = [];

        for (const approach of result) {
            for (const component of approach.approach.components) {
                const component_name = component.approachcomponent.name;
                component_options.push({
                    text: component_name,
                    value: component_name,
                });
            }
        }

        component_options.sort((a, b) => (a.value > b.value ? 1 : -1));

        this.setState({
            component_options,
        });
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    mcl_mapping_callback(result) {
        const mcl_mappings = [];
        for (const index in result) {
            const mcl_mapping = result[index].questionmclmapping;
            mcl_mappings.push(mcl_mapping);
        }

        this.setState({
            mcl_mappings,
            loaded: true,
        });
    }

    render() {
        const mcl_mappings = [];

        const score_disabled = {};
        let scored = null;
        if (this.state.scoring) {
            score_disabled.disabled = 'disabled';
        }
        if (this.state.scored) {
            scored = (
                <Alert type="success">Responses Scored Successfully!</Alert>
            );
        }

        for (const index in this.state.mcl_mappings) {
            const mcl_mapping = this.state.mcl_mappings[index];

            mcl_mappings.push(
                <MCLMapping
                    question_id={this.props.question_id}
                    mcl_mapping={mcl_mapping}
                    refresh_data={this.refresh_data}
                    component_options={this.state.component_options}
                />
            );
        }

        mcl_mappings.push(
            <MCLMapping
                question_id={this.props.question_id}
                refresh_data={this.refresh_data}
                component_options={this.state.component_options}
            />
        );

        const content = (
            <div className="container" test-data-id="mcl-mapping-container">
                <h1>MCL Mappings:</h1>
                <div className="shadowbox">{mcl_mappings}</div>
                <br />
                <br />
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default MCLMappings;
