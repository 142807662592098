
const HEADER_HEIGHT = 62;

const window_hostname = window.location.hostname;
let base_url = ""
if (window_hostname.includes("local")) {
  base_url = "http://localhost:3000";
} else if (window_hostname.includes("sb-beach")) {
  base_url = "https://mathanex-sb-beach.poc.learning.amplify.com";
} else if (window_hostname.includes("sb-desert")) {
  base_url = "https://mathanex-sb-desert.poc.learning.amplify.com";
} else if (window_hostname.includes("mathanex-devci")) {
  base_url = "https://mathanex-devci.poc.learning.amplify.com";
} else if (window_hostname.includes("mathanex-staging")) {
  base_url = "https://mathanex-staging.stage.learning.amplify.com";
} else if (window_hostname.includes("mathanex-prod")) {
  base_url = "https://mathanex-prod.learning.amplify.com";
} else if (window_hostname.includes("staging.mathanex")) {
  base_url = "https://staging.mathanex.com";
} else {
  base_url = "https://mathanex.com"
}
const BASE_DOMAIN = base_url

const MATH_ANEX_EMPLOYEE_ROLES = [
  'Super Admin',
  'Analyst',
  'Manager Analyst',
];

const DISTRICT_STAFF_ROLES = [
  'District Leader',
  'Principal',
  'District Admin',
  'Staff',
];

export {
    HEADER_HEIGHT,
    BASE_DOMAIN,
    MATH_ANEX_EMPLOYEE_ROLES,
    DISTRICT_STAFF_ROLES,
}
