import { BASE_DOMAIN } from 'constants';
import React, { Component } from 'react';




export default class ContactUs extends Component {
    render() {
        return (
            <div className="site-wrapper overflow-hidden position-relative">
                <header className="site-header site-header--menu-right landing-14-menu site-header--absolute site-header--sticky">
                    <div className="container">
                        <nav className="navbar site-navbar">
                            <div className="brand-logo">
                                <a href={`${BASE_DOMAIN}/`}>
                                    <img
                                        src="https://mathanex-media.learning.amplify.com/MathANEXLogo.png"
                                        alt=""
                                        style={{ height: '40px' }}
                                    />{' '}
                                    Math ANEX
                                </a>
                            </div>
                            <div className="menu-block-wrapper">
                                <div className="menu-overlay" />
                                <nav className="menu-block" id="append-menu-header">
                                    <div className="mobile-menu-head">
                                        <div className="go-back">
                                            <i className="fa fa-angle-left" />
                                        </div>
                                        <div className="current-menu-title" />
                                        <div className="mobile-menu-close">
                                            &times;
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div className="ms-auto d-none d-xs-inline-flex">
                                <a className="btn btn-success focus-reset" href="/">
                                    Home
                                </a>
                            </div>
                            <div className="ms-auto d-none d-xs-inline-flex">
                                <a
                                    target="_blank"
                                    className="btn btn-primary focus-reset"
                                    href="/login/"
                                >
                                    Log In
                                </a>
                            </div>

                            <div className="mobile-menu-trigger">
                                <span />
                            </div>
                        </nav>
                    </div>
                </header>

                <div className="cta-area-l-14">
                    <div className="container position-relative">
                        <div className="" />
                        <div className="row cta-area-l-14-content justify-content-center text-lg-start text-left">
                            <div className="col-lg-12">
                                <div className="cta-content">
                                    <h2 style={{ color: 'black' }}>
                                        Schedule A - Rates of Compensation and
                                        Duties of Contractor
                                    </h2>
                                    <p>
                                        Pursuant to Section 3 of the Independent
                                        Contractor Agreement, COMPANY will pay
                                        CONTRACTOR the following hourly
                                        compensation rates based on the
                                        CONTRACTOR’s duties:
                                    </p>
                                    <h4>Analyst</h4>
                                    <p>Rate: $25/hr</p>
                                    <p>
                                        Responsibilities: You will be working on
                                        a variety of questions. You will be
                                        assigned an experienced analyst who will
                                        help answer questions and review your
                                        work. You will be assigning student
                                        responses to different Ways of Thinking.
                                        In addition, you will be able to be
                                        involved in the Math Anex community,
                                        contributing to the conversations around
                                        how to assign explanations and improve
                                        the Math Anex system and experience
                                        through Slack and weekly meetings that
                                        you can attend.
                                    </p>

                                    <h4>Team Lead</h4>
                                    <p>Rate: $30/hr</p>
                                    <p>
                                        Responsibilities: You will have the same
                                        responsibilities as an Analyst, but you
                                        are also going to help guide analysts
                                        and review their work. Additional tasks
                                        include responding to analyst questions.
                                    </p>

                                    <h4>Senior Analyst</h4>
                                    <p>Rate: $35/hr</p>
                                    <p>
                                        Responsibilities: You will have the same
                                        responsibilities as an Analyst, but you
                                        are also going to help guide analysts
                                        and review their work. Additional tasks
                                        include responding to questions in
                                        Slack, leading analyst meetings and
                                        performing quality control.
                                    </p>

                                    <h4>Assessment Writer</h4>
                                    <p>Rate: $50/hr</p>
                                    <p>
                                        Responsibilities: You will be tasked
                                        with writing a variety items for
                                        assessments. This includes writing
                                        questions, analysis of results,
                                        descriptions of why you designed things
                                        the way you did, and making changes to
                                        items already written.
                                    </p>

                                    <h2 style={{ color: 'black' }}>
                                        Submission of Hours
                                    </h2>
                                    <p>
                                        Before the 15th and last day of the
                                        month, you will receive a form to fill
                                        out your hours. Note, Math Anex will
                                        also be keeping track of your hours. If
                                        there are no disagreements in the hours
                                        reported, Math Anex will pay the higher
                                        of the two hourly counts. Pursuant to
                                        Section 3 of the Independent Contractor
                                        Agreement, Math Anex reserves the right
                                        to amend or modify the above Rates of
                                        Compensation and Duties at any time at
                                        Math Anex’s sole discretion.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
