import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { TextInput, Alert, Header, Button, Wrapper } from 'library';
import { BASE_DOMAIN } from 'constants';

class PasswordResetRequest extends Component {
    constructor(props) {
        super(props);
        this.state = { email: '', error: '', sent: false, loaded: true };
        this.emailCallback = this.emailCallback.bind(this);
        this.email = this.email.bind(this);
    }

    handleChange = e => {
        const name = e.target.getAttribute('name');
        const newState = {};
        newState[name] = e.target.value;

        const newCompleteState = this.state;
        newCompleteState[name] = e.target.value;
        this.setState(newState);
    };

    email() {
        var user_email = this.state.email;

        ajaxWrapper(
            'POST',
            '/users/password_reset_email/',
            {
                email: user_email
            },
            this.emailCallback
        );
    }

    emailCallback(result) {
        this.setState({ sent: true });
    }

    render() {
        let emailState = this.state.email;
        const email_props = {
            value: emailState,
            name: 'email',
            label: 'Email:',
            placeholder: 'your_email@school_domain.com',
            handlechange: this.handleChange,
        };
        let errorState = this.state.error;
        let sentState = this.state.sent;
        let alert = <div />;
        if (errorState) {
            alert = <Alert text={errorState} type="danger" />;
        }

        let sent = <div />;
        if (sentState == true) {
            sent = (
                <Alert
                    text={`Your password reset email has been sent to ${emailState} if the account exists in our system.`}
                    type="success"
                />
            );
        }

        const content = (
            <div className="container">
                <Header
                    size={2}
                    text={
                        "Add your email and we'll send you a link to reset your password."
                    }
                />
                <TextInput {...email_props} />
                <Button
                    type="success"
                    text="Reset Password"
                    onClick={this.email}
                />
                {alert}
                {sent}
            </div>
        );

        return <Wrapper loaded={this.state.loaded} content={content} />;
    }
}
export default PasswordResetRequest;
