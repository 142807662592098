import React from 'react';

import { UserValidator } from 'functions';
import { Image, Icon, EmptyModal } from 'library';
import ContactUs from 'pages/reports/results_explorer/components/contact.js';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            help: false,
        };
    }

    render() {
        const button_width = 48;
        const icons = 45 * 2;
        const width =
            this.props.width -
            this.props.page.length * 15 -
            36 -
            icons -
            button_width;

        const back_button = (
            <div
                className="orange-button"
                onClick={() => window.history.back()}
            >
                <Icon
                    size={1}
                    icon_type="fas"
                    icon="chevron-left"
                    style={{ width: '14px', textAlign: 'center' }}
                />
            </div>
        );

        let help = null;
        if (this.state.help) {
            help = (
                <EmptyModal show onHide={() => this.setState({ help: false })}>
                    <ContactUs />
                </EmptyModal>
            );
        }

        let dashboard_link = null;
        if (this.props.page != 'Dashboard') {
            dashboard_link = [
                <a
                    href="/report_navigator/"
                    style={{
                        fontSize: '20px',
                        fontWeight: 600,
                        textDecoration: 'underline',
                        color: '#007bff',
                    }}
                >
                    Dashboard
                </a>,
                <div
                    style={{
                        fontSize: '20px',
                        fontWeight: 600,
                        display: 'inline-block',
                        margin: '0px 0px 0px 8px',
                    }}
                >
                </div>,
            ];
        }

        let page_display = this.props.page;
        if (this.props.page == 'Report') {
            page_display = (
                <div
                    style={{
                        display: 'inline-block',
                        fontSize: '20px',
                        fontWeight: 600,
                    }}
                >
                    {this.props.report_name}
                </div>
            );
        }

        return (
            <div>
                <div className="no-print" style={{ float: 'right' }}>
                    {new UserValidator().is_staff() && (
                        <div
                            style={{ padding: '14px', display: 'inline-block' }}
                        >
                            <a href="/work/">
                                <div
                                    className="purple-button"
                                    style={{
                                        display: 'inline-block',
                                        color: 'white',
                                        width: 'fit-content',
                                    }}
                                >
                                    Go to Admin / Analyst Page
                                </div>
                            </a>
                        </div>
                    )}
                    <a onClick={() => this.setState({ help: true })}>
                        <Image
                            src="/static/images/reporting/question-3-circle.svg"
                            className="icon purple"
                            css={{ margin: '18px 20px 18px 0px' }}
                        />
                    </a>
                    <a href="/logout/">
                        <Image
                            src="/static/images/reporting/Sign Out, Right.svg"
                            className="icon purple"
                            css={{ margin: '18px 20px 18px 0px' }}
                        />
                    </a>
                </div>

                <div
                    className="h1"
                    style={{
                        margin: '0px',
                        padding: '18px',
                        display: 'inline-block',
                        verticalAlign: 'top',
                    }}
                >
                    {dashboard_link} {page_display}
                </div>

                <div className="divider-line" />

                {help}
            </div>
        );
    }
}
